<template>
  <div
    class="group overflow-hidden bg-ems-tag1 hover:bg-ems-gray700 border-1 !hover:border-ems-gray500 !border-ems-tag1 box-border min-h-159px rounded-8px p-20px flex flex-col 2xl:flex-row items-center gap-20px relative"
    @mouseleave="isShowOptions = false"
  >
    <img
      :src="
        !item.imagesAvatar
          ? require('@/static/img/no-img.png')
          : item.imagesAvatar
      "
      :alt="item.imagesAvatar"
      width="120"
      height="120"
      class="rounded-8px w-[120px] h-[120px] flex-shrink-0 object-cover"
    />
    <div
      class="flex flex-col items-center 2xl:items-start gap-12px h-full justify-between"
    >
      <div class="flex flex-col items-center 2xl:items-start gap-12px">
        <div class="text-18px font-semibold text-ems-gray100">
          {{ item.name ? getName(item.name) : getName(item.profileUUID) }}
        </div>
        <div
          class="flex items-center flex-wrap gap-y-12px"
          v-if="
            type === OBJECT_TYPE.HUMAN &&
            item.ioGroups &&
            item.ioGroups.length > 0
          "
        >
          <span
            class="inline-block rounded-lg py-6px px-10px text-center mr-2 text-ems-gray200 bg-ems-gray600"
          >
            {{
              listGroups[item.ioGroups[0]]
                ? listGroups[item.ioGroups[0]].name.length > 20
                  ? `${listGroups[item.ioGroups[0]].name.substring(0, 20)}...`
                  : listGroups[item.ioGroups[0]].name
                : ''
            }}
          </span>
          <a-popover
            placement="top"
            overlay-class-name="popup-groups"
            v-if="item.ioGroups.length > 1"
          >
            <template #content>
              <div>
                <span
                  class="inline-block rounded-lg py-[6px] text-ems-gray100 text-sm px-3 bg-ems-gray600 uppercase mr-2 mb-2"
                  v-for="(data, idx) in item.ioGroups"
                  :key="idx"
                >
                  {{ listGroups[data].name }}
                </span>
              </div>
            </template>
            <span
              class="inline-block rounded-lg p-1 px-2 bg-ems-gray600 text-ems-gray100"
            >
              ...
            </span>
          </a-popover>
        </div>
        <span class="text-base text-ems-gray300"
          >{{ t('object_tracing.relationship') }}
          <span class="font-normal text-ems-gray300">
            {{
              getRelationship(item)
                ? getRelationship(item)
                : t('object_tracing.relationship-unknown')
            }}
          </span></span
        >
        <span
          v-if="type === OBJECT_TYPE.VEHICLE"
          class="text-base text-ems-gray300"
          >{{ t('object_tracing.vehicle-owner') }}
          {{ item.owner ? item.owner : t('object_tracing.unknown') }}
        </span>
        <span class="text-base text-ems-gray300"
          >{{ t('object_tracing.frequency') }}
          <span class="text-ems-main2 font-bold">{{
            `${item.count} ${t('object_tracing.times')}`
          }}</span></span
        >
      </div>
      <div class="flex flex-col items-center 2xl:items-start gap-12px">
        <div class="flex items-center gap-4">
          <button
            @click="viewInfo"
            class="btn-card-social !outline-ems-gray300 focus:outline-ems-gray300 hover:outline-ems-gray300 text-ems-gray300 text-sm"
          >
            {{ t('object_tracing.view-info') }}
          </button>
          <button
            @click="viewEvent"
            class="btn-card-social !outline-ems-boTro4_600 text-ems-boTro4_600 text-sm"
          >
            {{ t('object_tracing.view-event') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { FORM_MODE } from '@/util/common-constant';
import { WARNING_LEVEL, RELATIONSHIP } from '@/config/Constant.js';
import { OBJECT_TYPE } from '@/util/common-constant';
import { useRoute } from 'vue-router';

const { t } = useI18n();
const route = useRoute();
const props = defineProps({
  item: {
    default: {},
  },
  listGroups: {
    default: {},
  },
  type: {
    default: OBJECT_TYPE.HUMAN,
  },
});
const isShowOptions = ref(false);
const getName = (name) => {
  return name.length > 30 ? `${name.substring(0, 30)}...` : name;
};
const getRelationship = computed(() => (item) => {
  let arr = [];
  for (let i in item.relate) {
    if (item.relate[i]) {
      arr.push(t(RELATIONSHIP[i]));
    }
  }
  return arr.length > 0 ? arr.join(' ,') : '';
});
const viewInfo = () => {
  window.open(
    `/io-management/object-management/edit?id=${props.item.profileUUID}&type=${props.item.type}&mode=${FORM_MODE.VIEW}`,
    '_blank'
  );
};
const viewEvent = () => {
  window.open(
    `/trace-management/identification-event?eventId=${props.item.profileUUID}&objectId=${route.query.id}`,
    '_blank'
  );
};
</script>
<style scoped>

.shadow-custom {
  box-shadow: 0 4px 84px 0 #000000;
}

.btn-card-social {
  padding: 3px 16px 3px 16px;
  gap: 10px;
  border-radius: 23px;
  border: 1px;
  outline: solid 1px;
  width: fit-content;
  height: fit-content;
}

.btn-card-social:focus {
  padding: 3px 16px 3px 16px;
  gap: 10px;
  border-radius: 23px;
  border: 1px;
  outline: solid 1px;
}

</style>
