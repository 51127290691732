<template>
  <div class="m-3 p-3 bg-ems-gray800">
    <div>
      <div class="grid grid-cols-2 items-center px-20px pt-18px">
        <div
          class="flex items-center gap-2 text-ems-gray200 text-16px font-semibold uppercase"
        >
          <span>{{ t('object_information.common_object') }}</span>
          <span class="text-ems-error text-sm"
            >{{ !listMatching ? 0 : listMatching.length }}
            {{
              formState.type === OBJECT_TYPE.HUMAN
                ? t('object_information.human')
                : t('object_information.vehicle')
            }}</span
          >
        </div>
        <div class="flex justify-end items-center gap-16px">
          <div class="w-240px">
            <InputFilter
              v-model:value="formState.keyword"
              :show-count="false"
              :maxlength="100"
              :placeholder="t('common.search')"
              :allowClear="true"
              @change="onChange"
            >
              <template #prefix>
                <img src="@/static/img/icon/IconSearch.svg" alt="IconSearch" />
              </template>
            </InputFilter>
          </div>
          <div class="w-240px">
            <SelectFilter
              v-model:value="formState.type"
              :placeholder="''"
              :options="options"
            >
            </SelectFilter>
          </div>
        </div>
      </div>
      <div
        class="h-1px w-[calc(100%-40px)] mx-auto bg-ems-gray700 my-[24px]"
      ></div>
      <div v-if="isLoading" class="spin bg-transparent h-650px">
        <a-spin />
      </div>
      <div
        v-else
        class="pl-20px pr-10px mr-5px max-h-[calc(100vh-370px)] overflow-y-auto overflow-x-hidden"
      >
        <Empty
          v-if="!listMatching || listMatching.length === 0"
          :description="t('common.no_data_displayed')"
          class="mt-[60px]"
        />
        <div v-else class="grid grid-cols-3 gap-20px">
          <template v-for="item in listMatching">
            <CardSocial
              :item="item"
              :listGroups="listGroups"
              :type="formState.type"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import InputFilter from '@/components/input-filter/index.vue';
import SelectFilter from '@/components/select-filter/index.vue';
import CardSocial from './CardSocial.vue';
import Empty from '@/components/empty/index.vue';
import { OBJECT_TYPE } from '@/util/common-constant';
import { useRoute, useRouter } from 'vue-router';
import { debounce } from 'lodash';

const { t } = useI18n();
const route = useRoute();
const { state, dispatch } = useStore();
const formState = reactive({
  type: OBJECT_TYPE.HUMAN,
  keyword: '',
});
const options = [
  { label: 'object_information.human', value: OBJECT_TYPE.HUMAN },
  { label: 'object_information.vehicle', value: OBJECT_TYPE.VEHICLE },
];
const listMatching = computed(() => state.tracingObject.listFrequency);
const isLoading = ref(true);
const listGroups = computed(() => {
  let groups = {};
  state.ioGroup.listGroup.map((el) => {
    groups[el.id] = el;
  });
  return groups;
});
onMounted(async () => {
  await getData();
});
const getData = async () => {
  isLoading.value = true;
  await dispatch('tracingObject/getFrequency', {
    id: route.query.id,
    params: formState,
  });
  isLoading.value = false;
};
const onChange = debounce(() => {
  getData();
}, 300);
watch(
  () => formState.type,
  (val) => {
    getData();
  },
  {
    deep: true,
  }
);
</script>
<style scoped>
.shadow-custom {
  box-shadow: 0 4px 84px 0 #000000;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 67, 67, var(--tw-bg-opacity));
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(85, 85, 85, var(--tw-bg-opacity));
}
</style>
